import Vue from 'vue' ;
import VueCompositionAPI from '@vue/composition-api' ;
import { i18n } from "@/plugins/i18n";

Vue.use(VueCompositionAPI) ;
import { reactive } from '@vue/composition-api' ;

const state = reactive({
  endpoint: 'https://api.camping.care',
  locale: 'de',
  languages: ['de','nl','en'],
  timezone: null,
  currency: 'EUR',
  dateFormat: 'DD-MM-YYYY',
  dateMonthFormat: 'MM-YYYY',
  dateTimeFormat: 'DD-MM-YYYY hh:mm',
  loading: false,
  leftMenu: true,
  leftMenuTemporary: true,
  notificationDrawer: false,
  redirect: null,
  routerMode: 'history',
  mediaPopup: false,
  snackbar: {
    show: false,
    color: 'red',
    text: 'test'
  },
  style: {
    logo: 'https://storage.googleapis.com/campingcare-static/images/logo.svg',
    logoWidth: '120px',
    loaderColor: '#bb74ae',
    primary: '#a25293',
    secondary: '#cccccc',  
    headerBackground: '#5a5a5a'  
  },
  current_admin: null,
  flags: {
    showProfiletype: true,
    hideHeader: false,
    hideFooter: false,
    // leftMenuHide: false,
  },
  firebaseConfig : {
    apiKey: "AIzaSyBVniTJs9xS7DQQA2QJSJvaKxIQgcpHwF4",
    authDomain: "campingcare-api.firebaseapp.com",
    databaseURL: "https://campingcare-api.firebaseio.com",
    projectId: "campingcare-api",
    storageBucket: "campingcare-api.appspot.com",
    messagingSenderId: "614966291118",
    appId: "1:614966291118:web:dd36f75630384710a39b3f",
    measurementId: "G-KCT5LCLE1S"
  },
  availableLanguages: [
    {
        name: i18n.t('Dutch'),
        key: 'nl',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/nl_NL.svg',
    },
    {
        name: i18n.t('German'),
        key: 'de',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/de_DE.svg',
    },
    {
        name: i18n.t('English'),
        key: 'en',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/en_UK.svg',
    },
    {
        name: i18n.t('French'),
        key: 'fr',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/fr_FR.svg',
    },
    {
        name: i18n.t('Italian'),
        key: 'it',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/it_IT.svg',
    },
    {
        name: i18n.t('Spanish'),
        key: 'es',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/es_ES.svg',
    },
    {
        name: i18n.t('Danish'),
        key: 'dk',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/dk_DK.svg',
    },
    {
        name: i18n.t('Swedish'),
        key: 'se',
        image: 'https://storage.googleapis.com/campingcare-static/images/flags/sv_SV.svg',
    },

  ]

});


const setSnackbar = (snackbar) => {
  state.snackbar = snackbar
}

const setError = (errorMessage) => {
  
  let message = ''; 

  // console.log(errorMessage) ;
  if(errorMessage.response){
    message = errorMessage.response.data.error.message+" ("+errorMessage.response.data.error.code+")" ; 
  }else{
    message = errorMessage ; 
    //console.error(errorMessage) ;
  }

  let snackbar = {
      color: 'red',
      show: true,
      text: message
  }
  
  state.snackbar = snackbar
  
}

const setSuccess = (message) => {
  
  let snackbar = {
      color: 'green',
      show: true,
      text: message
  }
  
  state.snackbar = snackbar
  
}

const getFlag = (key) => {
  
  return state.flags[key] ;
  
}

const setFlag = (key, value) => {

  return state.flags[key] = value ;
  
}

const setCookie = (cname, cvalue, exdays) => {

  if(Array.isArray(cvalue)){
    cvalue = JSON.stringify(cvalue) ;
  }

  if(typeof cvalue === 'object'){
    cvalue = JSON.stringify(cvalue) ;
  }

  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

}

const deleteCookie = (cname) => {
  document.cookie =cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
}

function getCookie(cname) {

  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');

  var value = null ;

  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      value = c.substring(name.length, c.length);
    }
  }

  if(isJson(value)){
    value = JSON.parse(value) ;
  }

  return value ;
}

const isJson = (str) => {

  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }

  return true;

}

const getLanguage = (lang) => {

  return state.availableLanguages.find((x) => lang == x.key) ;

}

const getLanguageImage = (lang) => {

  let languageItem = getLanguage(lang)

  return languageItem.image ;

}

const getLanguageName = (lang) => {

  let languageItem = getLanguage(lang)

  return languageItem.name ;

}

export default { 
  state,
  setSnackbar,
  setError,
  setSuccess,
  getFlag,
  setFlag,
  setCookie,
  deleteCookie,
  getCookie,
  getLanguage,
  getLanguageImage,
  getLanguageName
} ;