
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBVniTJs9xS7DQQA2QJSJvaKxIQgcpHwF4",
  authDomain: "campingcare-api.firebaseapp.com",
  databaseURL: "https://campingcare-api.firebaseio.com",
  projectId: "campingcare-api",
  storageBucket: "campingcare-api.appspot.com",
  messagingSenderId: "614966291118",
  appId: "1:614966291118:web:dd36f75630384710a39b3f",
  measurementId: "G-KCT5LCLE1S"
};

firebase.initializeApp(firebaseConfig);

export default firebase