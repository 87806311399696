import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
        requiresAuth: false,
    },
  },

  {
    path: '/Login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
        requiresAuth: false,
    },
  },

  {
    path: '/login/token/:auth_token',
    name: 'LoginWithToken',
    component: () => import('../views/LoginWithToken.vue'),
    meta: {
      requiresAuth: false,
    },
    
  },
  
  {
    path: '/websites',
    name: 'websites',
    component: () => import('../views/websites/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
        requiresAuth: true,
    },
  },
  {
    path: '/settings/style',
    name: 'style',
    component: () => import('../views/settings/Style.vue'),
    meta: {
        requiresAuth: true,
    },
  },
  {
    path: '/settings/:section',
    name: 'settingsSection',
    component: () => import('../views/Settings.vue'),
    meta: {
        requiresAuth: true,
    },
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('../views/pages/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  {
    path: '/pages/:pageId',
    name: 'editPage',
    component: () => import('../views/pages/Edit.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  {
    path: '/news/:pageId',
    name: 'editPage',
    component: () => import('../views/pages/Edit.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  {
    path: '/menus',
    name: 'menus',
    component: () => import('../views/menus/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  

  {
    path: '/accommodations',
    name: 'accommodations',
    component: () => import('../views/accommodations/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },

  {
    path: '/administrations',
    name: 'administrations',
    component: () => import('../views/administrations/Index.vue'),
    meta: {
        requiresAuth: true,
    },
  },

]

let routerMode = 'history';

const router = new VueRouter({
  mode: routerMode,
  base: process.env.BASE_URL,
  routes
})

export default router
