import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const vuetify = new Vuetify()

Vue.use(TiptapVuetifyPlugin, {
// the next line is important! You need to provide the Vuetify Object to this place.
vuetify, // same as "vuetify: vuetify"
// optional, default to 'md' (default vuetify icons before v2.0.0)
iconsGroup: 'mdi'
})

export default vuetify