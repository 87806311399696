var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-overlay',{attrs:{"color":"#ffffff","value":_vm.app.state.loading || _vm.currentUser.state.loggedIn === null}},[_c('v-progress-circular',{attrs:{"color":_vm.app.state.style.loaderColor,"indeterminate":"","size":"64"}})],1),(
    !_vm.app.getFlag('hideHeader') && 
    !_vm.app.state.loading &&
    _vm.currentUser.loggedIn()
    )?_c('Header',{staticClass:"d-print-none",attrs:{"id":"cs_app_header"}}):_vm._e(),(
    !_vm.app.state.loading &&
    _vm.currentUser.state.loggedIn !== null && 
    (
      _vm.requiresAuth == false ||
      _vm.ctx.root.$route.name == 'websites' ||
      _vm.currentWebsite.id()
    ))?_c('v-content',{staticClass:"background"},[_c('router-view')],1):_vm._e(),(
    !_vm.app.getFlag('hideFooter') && 
    !_vm.app.state.loading &&
    _vm.currentUser.loggedIn() 
    )?_c('Footer',{staticClass:"d-print-none"}):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }