import { reactive } from '@vue/composition-api' ;
import currentWebsite from "@/store/websites/currentWebsite" ;
import firebase from 'firebase' ;

const state = reactive({
    data: null,
});

const id = () => {

    if(state.data?.id == null){
        return null ;
    }

    return state.data.id ;
}

const getName = () => {

    if(state.data?.data().name == null){
        return 'Unknown' ;
    }

    return state.data.data().name ;

}

const getlanguage = () => {

    if(state.data?.data().language == null){
        return '' ;
    }

    return state.data.data().language ;

}

const getPath = () => {

    if(state.data?.data().path == null){
        return '' ;
    }

    return state.data.data().path ;

}

const getSlug = () => {

    if(state.data?.data().slug == null){
        return 'unknown-slug' ;
    }

    return state.data.data().slug ;

}

const get = (pageId) => {

    state.data = null ;

    return firebase.firestore().collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(String(pageId)) ;

}

const getPage = () => {

    return firebase.firestore().collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(String(id())) ;

}

const set = (data) => {

    state.data = data ;

}

const update = (data) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .set(data, { merge: true })

}

const addComponent = (newId, data) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .collection("components")
    .doc(newId).set(data) ;

}

const getComponent = (componentId) => {

    return firebase.firestore().collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .collection("components")
    .doc(componentId) ;
    
}

const getComponents = () => {

    console.log('idid '+id());

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .collection("components")
    .orderBy("rank")
    .get() ;

}

const updateComponent = (componentId, data) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .collection("components")
    .doc(componentId)
    .set(data, { merge: true })

}

const removeComponent = (componentId) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .collection("components")
    .doc(componentId)
    .delete()

}

const updateSettings = (data) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("pages")
    .doc(id())
    .set(data, { merge: true })

}

export default { 
    state,
    getName,
    get,
    id,
    set,
    getSlug,
    addComponent,
    getComponents,
    updateComponent,
    removeComponent,
    getComponent,
    update,
    getlanguage,
    getPath,
    updateSettings,
    getPage
 } ;