// import Vue from 'vue' ;
// import { reactive } from '@vue/composition-api' ;

import firebase from '@/plugins/firebase' ;
import axios from "axios" ;
import app from "@/store/app" ;

const get = async (endpoint, params = {}) => {
  
  let config = await getConfig(params); 

  try {

    config.params = params ;

    return axios.get(getEndpoint(endpoint), config) ;
  }
  catch(err) {
    return err ;
  }

}

const post = async (endpoint, data = {}) => {
  
  let config = await getConfig(); 

  try {
    return axios.post(getEndpoint(endpoint), data, config) ;
  }
  catch(err) {
    return err ;
  }

}

const del = async (endpoint) => {
  
  let config = await getConfig() ; 

  try {
    return axios.delete(getEndpoint(endpoint), config) ;
  }
  catch(err) {
    return err ;
  }

}

const put = async (endpoint, data = {}) => {
  
  let config = await getConfig(); 

  try {
    return axios.put(getEndpoint(endpoint), data, config) ;
  }
  catch(err) {
    return err ;
  }

}

const getConfig = async () => {

  let token = null ;

  await firebase.auth().currentUser.getIdToken(/* forceRefresh */ false).then((idToken) => {
    token = idToken ;
  })

  let config = {
      headers: { 
        Authorization: "Bearer " + token,
      }      
  };

  return config ;

}

const getEndpoint = (endpoint) => {

  // if(process.env.NODE_ENV === 'development'){
  //   return 'http://localhost:8087/v2'+endpoint ;
  // }

  return app.state.endpoint+'/v2'+endpoint ;

}

export default { 
  get,
  post,
  del,
  put,
  getEndpoint, 
  getConfig,
} ;