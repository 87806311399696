import { reactive } from '@vue/composition-api' ;
import router from '@/router'
import app from "@/store/app" ;
import firebase from 'firebase' ;

const state = reactive({
    data: null,
    title: 'Website'
});

const id = () => {

    if(state.data?.id == null){
        return null ;
    }

    return state.data.id ;
}

const getName = () => {

    if(state.data?.name == null){
        return 'Unknown' ;
    }

    return state.data.name ;

}

const getTitle = () => {
    return state.title ;
}

const setTitle = (title) => {
    state.title = title ;
}

const get = (websiteId) => {

    firebase.firestore().collection("websites")
    .doc(String(websiteId)).onSnapshot((doc) => {

        set(doc) ;

    }) ;

}

const getHost = () => {

    if(state.data?.host == null){
        return 'camping.care' ;
    }

    return state.data.host ;

}

const getPrimaryLanguage = () => {

    if(state.data?.primary_language == null){
        return 'en' ;
    }

    return state.data.primary_language ;

}

const getStyle = () => {

    if (state.data?.style == null) {
        return "";
    }

    return state.data.style;

}

const getFooter = () => {

    if (state.data?.footer_data == null)
        return "";

    return state.data.footer_data;

}

const getWebsiteStyle = () => {

    if (state.data?.website_style == null) {
        return "";
    }

    return state.data.website_style;

}

const getMultiLanguage = () => {

    if (state.data?.multilanguage == null)
        return false;
    
    return state.data.multilanguage;

}

const set = (data) => {

    state.data = data.data() ;
    state.data.id = data.id ;

    app.setCookie('current_website', state.data.id) ;

}

const init = () => {

    let currentWebsiteId = null ;

    if(app.getCookie('current_website')){
        currentWebsiteId = app.getCookie('current_website') ;
    }

    if (currentWebsiteId) {
        get(currentWebsiteId) ;      
    }else{
        router.push('/websites') ;
    } 

    return true ;

}

const update = (data) => {

    return firebase.firestore()
    .collection("websites")
    .doc(String(id()))
    .set(data, { merge: true })

}

const getBookSlug = (language) => {

    if(state.data?.book_slug == null || state.data?.book_slug[language] == null){
        
        if(language == 'nl'){
            return 'boeken' ;
        }else if(language == 'de'){
            return 'buchen' ;
        }else if(language == 'it'){
            return 'prenotazione' ;
        }else if(language == 'fr'){
            return 'reservation' ;
        }else if(language == 'es'){
            return 'reserva' ;
        }

        return 'book' ;
        
    }

    return state.data?.book_slug[language] ;

}

export default { 
    state,
    getName,
    init,
    get,
    id,
    set,
    getTitle,
    setTitle,
    getHost,
    update,
    getPrimaryLanguage,
    getMultiLanguage,
    getBookSlug,
    getStyle,
    getWebsiteStyle,
    getFooter
 } ;