import { reactive } from '@vue/composition-api' ;

import firebase from '@/plugins/firebase'
import app from "@/store/app" ;
import api from "@/store/api" ;

const state = reactive({
    db: null,
    data: null,
    realtime: {},
    temps: null,
    notifications: {
        data: null,
        limit: 20,
        count: 0,
        offset: 1,
        loading: false
    },
    loggedIn: null
});

const id = () => {
    return state.db.ID ;
}

const uid = () => {
    console.log('state.data.uid', state.data);
    return state.data.uid ;
}

const displayName = () => {
    return state.db.display_name ;
}

const role = () => {
    return state.db.role ;
}

const loggedIn = () => {
    
    if(!state.loggedIn){
        return false ;
    }

    return state.loggedIn ;

}

const login = (email, password) => {
    
    return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(data => {
        
        return data ;

    })
    .catch(err => {

        console.log('user error', err);
        return err ; 

    });

}

const logout = () => {
    
    return firebase
    .auth()
    .signOut()
    .then(() => {
        console.log('user loggedout');
        // router.replace({ name: "Login" });
    })
    .catch(err => {

        console.log('user error', err);
        return err ; 

    });

}

const checkPassword = (email, password) => {

    let user = {
        email: email,
        password: password
    }
    
    return api.post('/users/password', user)
    .then((response) => {

        return response ;

    })
    .catch((error) => {
        
        return error ;

    });
    
}

const get = () => {

    api.get("/users/me")
    .then((response) => {
        
        state.db = response.data ;
        state.loggedIn = true ;

    })
    .catch(error => {
        app.setError(error);
        return error ;
    });
    
}

const init = () => {

    // const requiresAuth = router.matched.some((record) => record.meta.requiresAuth);


    // listen for an event when user status change
    firebase.auth().onAuthStateChanged((user) => {
        
        console.log('useruser', user) ; 

        if (user) {
            
            state.data = user ;
            get(); // get user from DB

        }else{
            state.data = null ;
            state.loggedIn = false ;
            console.log('user loggedout replace') ;
            // router.replace({ name: "Login" }) ;
        }

    });

}

export default { 
    state, 
    login, 
    logout, 
    loggedIn, 
    checkPassword,
    get,
    init,
    uid,
    id,
    role,
    displayName,
 } ;