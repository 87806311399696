<template>

  <v-app>

    <v-overlay
      color="#ffffff"
      :value="app.state.loading || currentUser.state.loggedIn === null">

      <v-progress-circular  :color="app.state.style.loaderColor" indeterminate size="64"></v-progress-circular>

    </v-overlay>
  
    <Header id="cs_app_header" class="d-print-none"
      v-if="
      !app.getFlag('hideHeader') && 
      !app.state.loading &&
      currentUser.loggedIn()
      "/>

    <v-content class="background" v-if="
      !app.state.loading &&
      currentUser.state.loggedIn !== null && 
      (
        requiresAuth == false ||
        ctx.root.$route.name == 'websites' ||
        currentWebsite.id()
      )"><router-view />

    </v-content>

    <Footer class="d-print-none" v-if="
      !app.getFlag('hideFooter') && 
      !app.state.loading &&
      currentUser.loggedIn() 
      "/>

      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
      >
        {{ snackbar.text }}

        <v-btn
          color="white"
          text
          @click="snackbar.show = false"
        >
          {{ $t('close') }}
        </v-btn>

      </v-snackbar>

  </v-app>

</template>


<style lang="scss">

// @import "@/assets/scss/main.scss" ;

.section {
  height: 100%;
}

.container {
  max-width: 1120px;
}

.menu_section {
  min-width: 256px;
}

.container_section {
  width: 100%;
  height: 100%;
}

.background {
    background-color: #f0f0f0;
}

</style>

<script>

import { computed, watch, ref } from '@vue/composition-api' ;

import Header from './components/Header' ;
import Footer from './components/Footer' ;

import app from "@/store/app" ;
import api from "@/store/api" ;
import currentUser from "@/store/users/currentUser" ;
import currentWebsite from "@/store/websites/currentWebsite" ;
import currentPage from "@/store/pages/currentPage" ;

export default {

  provide: {
    app,
    api,
    currentUser,
    currentWebsite,
    currentPage,
  },
  
  components: {
    Header,
    Footer
  },

  setup(props, ctx){

    const requiresAuth = ref(null) ;

    const snackbar = computed(() => {
        return app.state.snackbar ; 
    });

    watch(() => ctx.root.$route, () => {

        requiresAuth.value = ctx.root.$route.matched.some((record) => record.meta.requiresAuth);

    })

    watch(() => currentUser.state.loggedIn, () => {

        requiresAuth.value = ctx.root.$route.matched.some((record) => record.meta.requiresAuth);

        if(
          currentUser.loggedIn() === false && 
          requiresAuth.value == true
        ){
          
          // console.log('Redirect to login'); 

          app.state.redirect = ctx.root.$route.fullPath ;
          ctx.root.$router.push('/login') ;

        }

        if(currentUser.loggedIn()){
          currentWebsite.init() ; 
        }

    })

    // app.init() ;
    currentUser.init() ;

    return {
        currentUser,
        app,
        api,
        snackbar,
        ctx,
        requiresAuth,
        currentWebsite
    }

  },

}

</script>