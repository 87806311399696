<template>
    
    <v-card>
            
        <v-app-bar
        color="white"
        elevate-on-scroll
        >

            <v-btn icon to="/dashboard">
                <v-icon>mdi-home-outline</v-icon>
            </v-btn>

            <v-toolbar-title>{{ currentWebsite.getTitle() }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                
                <template v-slot:activator="{ on, attrs }">

                    <v-btn icon
                    v-bind="attrs"
                    v-on="on"
                    >
                        <v-icon>mdi-account-outline</v-icon>
                    </v-btn>

                </template>

                <v-card class="pa-4">

                    <v-btn
                        :color="app.state.style.primary"
                        depressed 
                        outlined
                        text
                        block
                        to="/websites"
                    > 

                        {{ currentWebsite.getName() }}

                        <v-spacer></v-spacer>

                        <v-icon right>
                        mdi-swap-horizontal
                        </v-icon>

                    </v-btn>

                </v-card>

                <v-list class="py-0">

                    <v-list-item to="/user/profile">
                        <v-list-item-title>USER NAME</v-list-item-title>
                        <v-list-item-icon><v-icon class="ml-2">mdi-account-outline</v-icon></v-list-item-icon>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="currentUser.logout();">
                        <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
                        <v-list-item-icon><v-icon class="ml-2">mdi-logout-variant</v-icon></v-list-item-icon>
                    </v-list-item>

                </v-list>

                </v-menu>
                
        </v-app-bar>

    </v-card>

</template>

<script>
    
import { inject, ref } from '@vue/composition-api' ;

export default {

    setup(){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;
        
        const drawer = ref(false) ;
        
        return {
            app,
            drawer,
            currentUser,
            currentWebsite
        }
    
    },

};

</script>