import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
    en: require("@/locale/en.json"),
    de: require("@/locale/de.json"),
    nl: require("@/locale/nl.json")
};

export const i18n = new VueI18n({
    // locale: app.state.locale, //Locale
    fallbackLocale: "en", //set fallback locale,
    messages,
    silentTranslationWarn: true
});
